var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "containerFlexRow" }, [
    _c("div", { staticClass: "containerFlexRow__left" }, [
      _c(
        "div",
        { staticClass: "containerFlexColumn" },
        [
          _c(
            "ul",
            { staticClass: "wHP" },
            _vm._l(_vm.navList, function (nav) {
              return _c(
                "li",
                {
                  key: nav.id,
                  staticClass: "nav",
                  on: {
                    click: function ($event) {
                      return _vm.link(nav.path)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "iconfont fs30" }, [
                    _vm._v(_vm._s(_vm._f("unescapeIcon")(nav.icon))),
                  ]),
                  _c("span", [_vm._v(_vm._s(nav.menuName))]),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "marginTopAuto marginBottom10" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "right",
                    width: "250",
                    trigger: "manual",
                  },
                  model: {
                    value: _vm.visible,
                    callback: function ($$v) {
                      _vm.visible = $$v
                    },
                    expression: "visible",
                  },
                },
                [
                  _c("div", { staticClass: "h300 flex directionColumn" }, [
                    _c("div", { staticClass: "hFP flex" }, [
                      _c(
                        "div",
                        { staticClass: "flex", staticStyle: { width: "25%" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "h50",
                              on: {
                                click: function ($event) {
                                  return _vm.openDialog(4)
                                },
                              },
                            },
                            [
                              _c("el-avatar", {
                                staticClass: "pointer",
                                attrs: {
                                  size: 50,
                                  src: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "flex1" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex",
                            staticStyle: { "align-items": "center" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "fs20 blue pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.openDialog(1)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.user.nickName))]
                            ),
                            _c("i", {
                              staticClass:
                                "el-icon-lock marginLeftAuto pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.openDialog(2)
                                },
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "blue pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openDialog(3)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.user.phonenumber))]
                        ),
                        _c("div", [_vm._v("超级管理员")]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "hFP userMenu" },
                      [
                        _c(
                          "el-menu",
                          {
                            staticClass: "el-menu-vertical-demo hFP",
                            attrs: { "default-active": "2" },
                            on: {
                              open: _vm.handleOpen,
                              close: _vm.handleClose,
                            },
                          },
                          [
                            _c(
                              "el-menu-item",
                              { attrs: { index: "1", disabled: "" } },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v("消息中心")]
                                ),
                              ]
                            ),
                            _c(
                              "el-menu-item",
                              {
                                attrs: { index: "2" },
                                on: { click: _vm.recharge },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v("充值中心")]
                                ),
                              ]
                            ),
                            _c(
                              "el-menu-item",
                              { attrs: { index: "3", disabled: "" } },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v("帮助中心")]
                                ),
                              ]
                            ),
                            _c(
                              "el-menu-item",
                              {
                                attrs: { index: "4" },
                                on: { click: _vm.quitLogin },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v("退出登陆")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "pointer",
                      attrs: { slot: "reference" },
                      on: {
                        click: function ($event) {
                          _vm.visible = !_vm.visible
                        },
                      },
                      slot: "reference",
                    },
                    [
                      _c("el-avatar", {
                        attrs: { icon: "el-icon-user-solid" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "30%",
                "before-close": _vm.handleDialogClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _vm.dialogVisible
                ? _c("userInfoSetting", {
                    attrs: { type: _vm.setType },
                    on: { closedDialog: _vm.closedDialog },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "containerFlexRow__right box-shadow" }, [
      _c(
        "ul",
        { staticClass: "wHP" },
        _vm._l(_vm.children, function (nav) {
          return _c(
            "li",
            {
              key: nav.id,
              staticClass: "nav",
              on: {
                click: function ($event) {
                  return _vm.link2(nav)
                },
              },
            },
            [
              _c("span", { class: { selected: nav.selected } }, [
                _vm._v(_vm._s(nav.menuName)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }