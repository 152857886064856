var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c("div", { staticClass: "app-container__left" }, [_c("v-nav")], 1),
    _c("div", { staticClass: "app-container__right" }, [
      _c(
        "div",
        { staticClass: "app-container__right--body scrollbar box-shadow" },
        [_c("router-view")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }